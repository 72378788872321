/* General reset for margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%; /* Ensure the body spans the full height */
}

body {
    font-family: 'Arial', sans-serif;
    background-color: #1e1e1e; /* Dark background color */
    color: #e0e0e0; /* Light gray text color */
    margin: 0;
}

.main-content {
    margin-top: 70px; /* Adjust this value to match the navbar height */
}

/* Headings */
h1, h2 {
    color: #ffffff; /* White headings */
    font-weight: bold;
}

/* Container */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
}

.main-brand-title {
    font-family: 'Fjalla One', sans-serif;
}

/* Custom styles for buttons and forms */
input[type="text"] {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #333;
    background-color: #2e2e2e;
    color: #e0e0e0;
    border-radius: 8px;         /* 5px -> 6px, ufak değişim */
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input[type="text"]:focus {
    border-color: #ffd500;
    outline: none;
    box-shadow: 0 0 6px rgba(255, 213, 0, 0.5); /* İnce sarı bir vurgu */
}

button {
    background-color: #ffd500;
    color: #1a1a1a;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
    background-color: #ffe257;
    transform: translateY(-2px);
}

/* Form and results styling */
form {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    margin-bottom: 40px;
}

.result-container {
    margin-top: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

/* Similar products styling */
.list-group-item {
    display: flex;
    align-items: center;
    padding: 15px;               /* Aynı kalabilir veya 1rem/1.2rem gibi kullanabilirsiniz */
    margin-bottom: 10px;
    background-color: #2e2e2e;
    border: 1px solid #444;      /* Biraz daha açık bir renk kullanarak yumuşattık */
    border-radius: 8px;          /* 5px -> 8px, daha modern görünüm */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Gölgeyi biraz genişlettik */
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.list-group-item:hover {
    background-color: #3a3a3a;   /* Hover'da biraz daha aydınlık (daha doğrusu griye yakın) */
    transform: translateY(-2px); /* Kartlar hover’da yukarı kalkıyor hissi */
}

/* Align image to the left and details to the right */
.result-container img {
    width: 100px;
    height: 100px;
    min-width: 100px;
    max-width: 100px;
    margin-right: 20px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}

/* Styling for product link and details */
.product-link {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    flex: 1;
}

.product-link:hover {
    color: #ffd500;
}

.product-details {
    flex: 1;
    padding-right: 15px;
}

.product-details h5 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #c1bdbd;
}

.product-source {
    font-size: 0.9rem;
    color: #888;
}

.product-price {
    /* Existing styles here */
    font-weight: bold;
    color: white;
    font-size: 1.25rem; /* Adjust size as desired */
}

.product-price:hover {
    color: #fdef99;
}

.discount-rate {
    font-weight: bold;
    font-size: 1.25rem; /* Adjust size as desired */
    color: #19bb70;
}

/* Add to Collection button styling */
.btn.btn-link {
    color: #ffd500;
    text-decoration: none;
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.2s ease;
    margin-left: 15px; /* Space between details and button */
}

.btn.btn-link:hover {
    color: #ffe257;
}

.btn.btn-link.btn-collection-icon {
    font-size: 1.5rem;
    padding: 10px;
}

/* User-provided product styling */
.user-product {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.user-product .list-group-item {
    display: block;
    text-align: center;
    padding: 20px;
    border: 2px solid #ffd500;    /* Mevcut kalabilir; isterseniz 1px'e indirebilirsiniz */
    background-color: #2e2e2e;
    border-radius: 8px;           /* Yuvarlak köşeler */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.user-product:hover .list-group-item {
    background-color: #3a3a3a;
    transform: translateY(-2px);
}

.user-product img {
    width: 150px;
    height: 150px;
    margin: 0 auto 15px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}

.user-product .product-details {
    text-align: center;
    right: auto;
}

.user-product .product-thumbnail {
    right: auto;
}

.user-product h5 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #fdef99;
}

.user-product .product-source {
    font-size: 0.9rem;
    color: #888;
}

/* Align form and results */
.row.justify-content-center {
    margin: 0;
}

.col-md-8 {
    padding: 0;
}

/* Horizontal line styling */
hr {
    border: none;
    height: 2px;
    background-color: #858585;
    max-width: 800px;
    margin: 20px auto;
}

.navbar {
    background-color: #2e2e2e;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    position: sticky;
    top: 0;
    z-index: 1000;
}

.navbar-links {
    display: flex;
    gap: 20px; /* Spacing between the links */
}

.navbar-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

/* On small screens, remove absolute positioning and use flex centering */
@media (max-width: 576px) {
    .navbar-center {
        position: static;
        transform: none;
        left: auto;
        display: flex;          /* Enable flex */
        justify-content: center; /* Center horizontally */
        width: 100%;            /* Ensure it can span the full width */
    }
}

.nav-link {
    color: #e0e0e0;
    text-decoration: none;
    transition: color 0.3s;
}

.nav-link:hover {
    color: #ffd500;
}

.disabled-link {
    color: #777;
    cursor: not-allowed;
    pointer-events: none;
}

.nav-link-wrapper {
    position: relative;
}

.tooltip {
    position: absolute;
    top: 100%; /* Position below the link */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #e0e0e0;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    white-space: nowrap;
    margin-top: 5px;
    opacity: 0.9;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.navbar .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffd500;
    text-decoration: none;
    font-family: 'Fjalla One', sans-serif;
}

/* Footer Styling */
footer {
    background-color: #2e2e2e;
    color: #e0e0e0;
    padding: 20px 0;
    text-align: center;
    font-size: 1rem;
    margin-top: 40px;
    border-top: 4px solid #ffd500;
}

/* Spinner styling */
.spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: #ffd500;
    animation: spin 1s ease-in-out infinite;
    margin: 0 auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Modal overlay styling */
.collection-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

/* Modal styling */
.collection-modal {
    background-color: #2e2e2e;
    padding: 20px;
    border-radius: 8px; /* 10px -> 8px */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Biraz artırdık */
    color: #e0e0e0;
    width: 300px;
    max-width: 90%;
    text-align: center;
}

.collection-modal h2 {
    color: #ffd500;
    font-size: 1.5rem;
    margin-bottom: 15px;
}

/* Collection list styling */
.collection-list {
    list-style: none;
    padding: 0;
    margin: 0 0 15px;
}

.collection-item {
    margin-bottom: 10px;
}

.collection-button {
    background-color: #ffd500;
    color: #1a1a1a;
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
}

.collection-button:hover {
    background-color: #ffe257;
}

/* New collection input and button */
.new-collection {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    flex-direction: column;
    align-items: center;
}

.collection-input {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
    border: 2px solid #333;
    background-color: #1e1e1e;
    color: #e0e0e0;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

.collection-input:focus {
    border-color: #ffd500;
    outline: none;
}

.create-collection-button {
    background-color: #ffd500;
    color: #1a1a1a;
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.create-collection-button:hover {
    background-color: #ffe257;
}

/* Sign-In Modal Styling */
.sign-in-modal {
    background-color: #2e2e2e;
    padding: 20px;
    border-radius: 8px; /* 10px -> 8px */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Biraz artırdık */
    color: #e0e0e0;
    width: 300px;
    max-width: 90%;
    text-align: center;
}

.sign-in-modal h2 {
    color: #ffd500;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.sign-in-modal p {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #e0e0e0;
}

.modal-button {
    width: 100%;
    padding: 10px;
    font-size: 0.9rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

.google-login-wrapper {
    width: 150px; /* Set to the maximum width expected for the Google Sign-In button */
    height: 50px; /* Set to the maximum height of the button */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevents content overflow during image change */
}

.close-modal-button {
    background-color: #333;
    color: #e0e0e0;
    border: none;
    margin-top: 15px;
}

.close-modal-button:hover {
    background-color: #555;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* Collections Page Styling */
.collection-card {
    background-color: #2e2e2e;
    border: 1px solid #444;              /* Ekledik */
    border-radius: 8px;                  /* 10px -> 8px, daha uyumlu */
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 800px;
}

.collection-title {
    color: #ffd500;
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: left;
}

.collection-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.collection-item-link {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
}

.collection-item-link:hover {
    transform: scale(1.1);
}

.collection-item-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.no-items-message {
    color: #777;
    font-size: 1rem;
    font-style: italic;
    text-align: center;
    margin-top: 10px;
    padding: 2px;
    background-color: #2e2e2e;
    border-radius: 5px;
}

.no-collections-message {
    color: #777;
    font-size: 1rem;
    font-style: italic;
    text-align: start;
    margin-top: 10px;
    padding: 2px;
}

.no-collections-modal-message {
    color: #777;
    font-size: 1rem;
    font-style: italic;
    text-align: center;
    margin-top: 10px;
    padding: 2px;
    background-color: #2e2e2e;
    border-radius: 5px;
}

.btn.btn-primary {
    background-color: #ffd500; /* Set initial background color */
    color: #1a1a1a; /* Text color (dark for contrast) */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn.btn-primary:hover {
    background-color: #ffe257; /* Hover background color */
    transform: translateY(-2px); /* Optional hover effect */
}

::-webkit-input-placeholder {
    color: #b3b3b3 !important;  /* Chrome/Safari/Opera */
}

::-moz-placeholder {
    color: #b3b3b3 !important;  /* Firefox 19+ */
}

:-moz-placeholder {
    color: #b3b3b3 !important;  /* Firefox 4 - 18 */
}

:-ms-input-placeholder {
    color: #b3b3b3 !important;  /* IE 10+ */
}

::placeholder {
    color: #b3b3b3 !important;  /* Standard modern selector */
}

/* In your styles.css (near the bottom or wherever you prefer) */
.sort-dropdown-container {
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.sort-label {
    font-weight: bold;
}

.sort-dropdown {
    background-color: #2e2e2e;
    color: #e0e0e0;
    border: 2px solid #333;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.sort-dropdown:focus {
    outline: none;
    border-color: #ffd500;
}
